<template>
  <div>
    <b-form @submit.prevent="update">
      <b-row>

        <!-- name -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Name')"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              :placeholder="$t('Name')"
              v-model="product.name"
            />
          </b-form-group>
        </b-col>

        <!-- SEO Name -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Name (SEO)')"
            label-for="v-seo-name"
          >
            <b-form-input
              id="v-seo-name"
              :placeholder="$t('Name (SEO)')"
              v-model="product.seo_name"
            />
          </b-form-group>
        </b-col>

        <!-- type -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Type')"
            label-for="v-type"
          >
            <b-form-select
              id="v-type"
              v-model="product.product_type"
              :options="productTypeOptions"
            />
          </b-form-group>
        </b-col>

        <!-- product code -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Product Code')"
            label-for="v-product_code"
          >
            <b-form-input
              id="v-product_code"
              placeholder="SA023"
              v-model="product.product_code"
            />
          </b-form-group>
        </b-col>

        <!-- quantity -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Quantity')"
            label-for="v-quantity"
          >
            <b-form-input
              id="v-quantity"
              type="text"
              placeholder="100"
              v-model="product.quantity"
            />
          </b-form-group>
        </b-col>

        <!-- cost price -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Cost Price')"
            label-for="v-cost_price"
          >
            <b-input-group>
              <b-form-input
                id="v-cost_price"
                placeholder="30"
                v-model="product.cost_price"
              />
              <b-input-group-append is-text>
                {{$t('SAR')}}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- original_price -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Original Price')"
            label-for="v-original_price"
          >
            <b-input-group>
              <b-form-input
                id="v-original_price"
                placeholder="45"
                v-model="product.original_price"
              />
              <b-input-group-append is-text>
                {{$t('SAR')}}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- price_after_discount -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Price After Discount')"
            label-for="v-price_after_discount"
          >
            <b-input-group>
              <b-form-input
                id="v-price_after_discount"
                placeholder="40"
                v-model="product.price_after_discount"
              />
              <b-input-group-append is-text>
                {{$t('SAR')}}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- categories -->
        <b-col cols="6">
          <b-form-group
            :label="$t('Categories')"
            label-for="v-categories"
          >
            <v-select
              v-model="product.categories"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :options="categories"
            />
          </b-form-group>
        </b-col>

        <!-- sort -->
        <b-col md="6">
          <b-form-group
            :label="$t('Sort')"
            label-for="v-sort"
          >
            <b-form-input
              id="v-sort"
              type="tel"
              placeholder="1"
              v-model="product.sort"
            />
          </b-form-group>
        </b-col>

        <!-- default -->
        <b-col md="6">
          <b-form-group
            :label="$t('Availability Date')"
            label-for="v-availability_date"
          >
            <flat-pickr
              v-model="product.availability_date"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <!-- default -->
        <b-col md="6">
          <b-form-group
            :label="$t('Available?')"
            label-for="v-availability"
          >
            <b-form-checkbox
              :checked="product.availability == 1 ? true : false"
              class="custom-control-primary"
              name="availability"
              @change="changeAvailable"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: ['product'],
  data() {
    return {
      productTypeOptions: [
        { value: 'product', text: this.$t('Product') },
        { value: 'file', text: this.$t('Digital') },
        { value: 'service', text: this.$t('Service') },
      ],
      categories: [],
      isLoadingSave: false
    }
  },
  mounted() {
    useJwt.get('/categories/get-all')
    .then((response) => {
        this.categories = response.data.data.data
    })
    .catch(response => {
        console.log(response);
    });
  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/products/update',this.product)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    changeAvailable(){
      if(this.product.availability == 0) {
        this.product.availability = 1
      } else {
        this.product.availability = 0
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
