<template>
  <div>
    <b-row>

      <b-col cols="12">
        <template v-for="(product_options_type, i_option_type) in product.productOptionsGrouped">
          <table class="table" :key="i_option_type">
            <thead>
              <th>
                {{$t('Option Type')}}: 
                <b-badge variant="light-success" class="fd-top-position-title">
                  {{product_options_type[0].product_option_type.name}}
                </b-badge>
              </th>
            </thead>
            <tbody>
              <template v-for="(product_option, i_option) in product_options_type">
              <tr :key="i_option">
                <td>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Name')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Name')"
                          v-model="product_option.name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Product Code')"
                        label-for="v-sku"
                      >
                        <b-form-input
                          id="v-sku"
                          :placeholder="$t('Product Code')"
                          v-model="product_option.sku"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Original Price')"
                        label-for="v-original_price"
                      >
                        <b-form-input
                          id="v-original_price"
                          :placeholder="$t('Original Price')"
                          v-model="product_option.original_price"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Price After Discount')"
                        label-for="v-price_after_discount"
                      >
                        <b-form-input
                          id="v-price_after_discount"
                          :placeholder="$t('Price After Discount')"
                          v-model="product_option.price_after_discount"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Quantity')"
                        label-for="v-quantity"
                      >
                        <b-form-input
                          id="v-quantity"
                          :placeholder="$t('Quantity')"
                          v-model="product_option.quantity"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('Availability Date')"
                        label-for="v-availability_date"
                      >
                        <flat-pickr
                          v-model="product_option.availability_date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('Available?')"
                        label-for="v-availability"
                      >
                        <b-form-checkbox
                          :checked="product_option.availability"
                          class="custom-control-primary"
                          name="availability"
                          v-model="product_option.availability"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="gradient-primary"
                        class="mr-1"
                        size="sm"
                        @click="update(product_option)"
                      >
                        <template v-if="!isLoadingSave">
                          <feather-icon icon="EditIcon" />
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </td>
              </tr>
              </template>
            </tbody>
          </table>
        </template>
      </b-col>
    </b-row>

    <hr>
    <!-- buttons -->
    <b-row>
      <b-col cols="6" class="text-left">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="gradient-primary"
          @click="$refs.productOptionModal.openProductOptionModal()"
        >
          <feather-icon icon="PlusSquareIcon" />
          {{$t('Add')}} {{$t('Product Option')}}
        </b-button>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="gradient-success"
          @click="$refs.productOptionTypeModal.openProductOptionTypeModal()"
        >
          <feather-icon icon="PlusSquareIcon" />
          {{$t('Add')}} {{$t('Product Option Type')}}
        </b-button>
      </b-col>
    </b-row>
  <!-- productOptionTypeModal -->
  <product-option-type-modal ref="productOptionTypeModal"></product-option-type-modal>
  <!-- productOptionModal -->
  <product-option-modal :product_id="product.id" ref="productOptionModal"></product-option-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ProductOptionTypeModal from './ProductOptionTypeModal.vue'
import ProductOptionModal from './ProductOptionModal.vue'

export default {
  components: { 
    FeatherIcon , 
    ProductOptionTypeModal, 
    ProductOptionModal
  },
  directives: {
    Ripple,
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false,
    }
  },
  mounted() {

  },
  methods: {
    update(product_option) {
      // console.log(product_option);
      this.isLoadingSave = true;
      let form = product_option;
      form.store_id = this.$store.state.store.id, 

      useJwt.post('/product-options/update',form)
      .then((response) => {
        // console.log(response.data);
        this.$parent.product = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    deleteOptions(i) {
      this.product.options.splice(i, 1);
    },
    changeAvailable(product_option){
      if(product_option.availability == false) {
        product_option.availability = true
      } else {
        product_option.availability = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
