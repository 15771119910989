<template>
  <div>
    <b-row>

      <b-col cols="12">
        <h6>{{$t('Product Images')}}</h6>
        <b-row class="match-height m-0">
          <template v-for="(image, index) in product.media">
            <div
              :key="index" 
              class="img-wrap mr-1 mb-1 float-left text-center"
            >
              <b-img-lazy
                :src="image.url" 
                width="100" 
                rounded
                thumbnail
              />
              <br>
              <b-button
                variant="flat-danger"
                size="sm"
                @click="deleteImage(index)"
                :disabled="isLoadingDelete !=  -1"
              >
                <feather-icon
                  icon="XIcon"
                  size="15"
                  v-if="isLoadingDelete != index"
                />
                <b-spinner small v-if="isLoadingDelete == index" />
              </b-button>
            </div>
          </template>
        </b-row>
      </b-col>

      <b-col cols="12">
        <hr>

        <!-- upload image -->
        <div class="image-upload-wrap" v-if="!cropImage">
          <input class="file-upload-input" type='file' @change="checkImageDimensions($event)" accept="image/jpg, image/jpeg, image/png, image/gif" />
          <div class="drag-text">
            <h4 class="text-primary">

              <template v-if="!isLoadingSave">
                <feather-icon
                  icon="UploadCloudIcon"
                  size="50"
                /><br>
                {{$t('Drag and drop a file or click to add Image')}}<br>
                <small class="text-warning">{{$t('Best Dimensions: 1000px * 1000px')}}</small>
              </template>

              <template v-if="isLoadingSave">
                <b-spinner />
              </template>
            </h4>
          </div>
        </div>

        <!-- crop image -->
        <div v-else>
          <cropper
            class="cropper"
            ref="cropper"
            :src="img"
            :stencil-props="{
              handlers: {},
              movable: false,
              scalable: false,
            }"
            :stencil-size="{
              width: 1000,
              height: 1000
            }"
            image-restriction="stencil"
          />     
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1 mt-1"
            @click="uploadImage(null)"
          >
            <template v-if="!isLoadingSave">
              {{$t('Upload')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Uploading')}}...
              <b-spinner small />
            </template>
          </b-button>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    Cropper
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false,
      isLoadingDelete: -1,
      img: null,
      cropImage: false,
    }
  },
  mounted() {
    
  },
  methods: {
    checkImageDimensions(event) {
      let file = event.target.files[0]
      if(!file || file.type.indexOf('image/') !== 0) return;

      if (file > (3 * 1024 * 1024)) { // 3 MB
        this.cropImage = true
      }

      let reader = new FileReader();
      
      reader.readAsDataURL(file);
      reader.onload = evt => {
        let img = new Image();
        img.src = evt.target.result;
        this.img = img.src
        img.onload = () => {
          if (img.width != img.height) {
            this.cropImage = true
          }

          if (img.width > 1000 || img.height > 1000) {
            this.cropImage = true
          }

          if (!this.cropImage) {
            this.uploadImage(file)
          }
        }
      }

      reader.onerror = evt => {
        console.error(evt);
      }
    },
    uploadImage(file) {
			const { canvas } = this.$refs.cropper ? this.$refs.cropper.getResult() : false;
			if (canvas) {
				const form = new FormData();
				canvas.toBlob(blob => {
					form.append('file', blob);
					form.append('id', this.product.id);

          this.isLoadingSave = true;
          useJwt.post('/products/upload-image',form)
          .then((response) => {
            // console.log(response.data);
            this.$parent.$parent.product = response.data.data
            this.isLoadingSave = false
            this.$root.showToast('success', 'save', '')
          })
          .catch(error => {
            // console.log(response);
            this.isLoadingSave = false
            this.$root.showAlert('error', error.response.data.message)
          });
					// Perhaps you should add the setting appropriate file format here
				}, 'image/png');
			}

      if (file) {
        const form = new FormData();
        form.append('file', file);
        form.append('id', this.product.id);
        
        this.isLoadingSave = true;
        useJwt.post('/products/upload-image',form)
        .then((response) => {
          // console.log(response.data);
          this.$parent.$parent.product = response.data.data
          this.isLoadingSave = false
          this.$root.showToast('success', 'save', '')
        })
        .catch(error => {
          // console.log(response);
          this.isLoadingSave = false
          this.$root.showAlert('error', error.response.data.message)
        });
      }
		},
    deleteImage(i) {

      this.isLoadingDelete = i
      useJwt.post('/products/delete-image',{id: this.product.media[i].id})
      .then((response) => {
        // console.log(response.data);
        this.$parent.$parent.product = response.data.data
        this.$delete(this.$parent.$parent.product.media, i);
        this.isLoadingDelete = -1
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingDelete = -1
        this.$root.showAlert('error', error.response.data.message)
      });

    }
  }
}
</script>

<style lang="scss">
.cropper {
	height: 500px;
	width: 500px;
	background: #DDD;
}

.image-upload-wrap {
  position: relative;
  margin-top: 20px;
  border: 1px dashed #ccc;
  border-radius: 10px;
}

.image-upload-wrap:hover {
  background-color: #f7f7f7;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.drag-text {
  text-align: center;
}

.drag-text h4 {
  font-weight: 100;
  padding: 30px 0;
}

</style>
