<template>
    <div class="product-edit-list-wrapper">
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
            >
            <template v-if="Object.keys(product).length > 0">
                <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
                >
                    <div>
                        <div
                        class="body-content-overlay"
                        :class="{'show': mqShallShowLeftSidebar}"
                        @click="mqShallShowLeftSidebar = false"
                        />
                        <div class="todo-app-list">

                            <!-- App Toggler Header -->
                            <div class="app-fixed-search d-flex align-items-center p-0 mb-1">
                                <!-- Toggler -->
                                <div class="sidebar-toggle d-block d-lg-none">
                                <feather-icon
                                    icon="MenuIcon"
                                    size="21"
                                    class="cursor-pointer"
                                    @click="mqShallShowLeftSidebar = true"
                                />
                                </div>
                            </div>


                            <!-- Product Information -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'product-information'">
                                <product-information :product="product"></product-information>
                            </div>

                            <!-- Product Images -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'product-image'">
                                <product-images :product="product"></product-images>
                            </div>

                            <!-- Product Descriptions -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'product-descriptions'">
                                <product-descriptions :product="product"></product-descriptions>
                            </div>

                            <!-- Product Attributes -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'product-attributes'">
                                <product-attributes :product="product"></product-attributes>
                            </div>
                            
                            <!-- Product Options -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'product-options'">
                                <product-options :product="product"></product-options>
                            </div>
                            
                            <!-- Related Products -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'related-products'">
                                <related-products :product="product"></related-products>
                            </div>

                            <!-- Quantities In Warehouses -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'quantities-in-warehouses'">
                                <quantities-in-warehouses :product="product"></quantities-in-warehouses>
                            </div>

                            <!-- The Digital File -->
                            <div class="todo-task-list-wrapper" v-if="tabOpened == 'digital-file'">
                                <the-digital-file :product="product"></the-digital-file>
                            </div>
                        </div>
                    </div>
                </transition>
            </template>
        </b-overlay>
        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <edit-product-left-sidebar
            ref="sidebar"
            :class="{'show': mqShallShowLeftSidebar}"
            @close-left-sidebar="mqShallShowLeftSidebar = false"
            />
        </portal>
    </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import {
  ref, watch, computed,
} from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import EditProductLeftSidebar from './edit/EditProductLeftSidebar.vue'
import ProductInformation from './edit/ProductInformation.vue'
import ProductDescriptions from './edit/ProductDescriptions.vue'
import ProductAttributes from './edit/ProductAttributes.vue'
import ProductOptions from './edit/ProductOptions.vue'
import RelatedProducts from './edit/RelatedProducts.vue'
import ProductImages from './edit/ProductImages.vue'
import QuantitiesInWarehouses from './edit/QuantitiesInWarehouses.vue'
import TheDigitalFile from './edit/TheDigitalFile.vue'


export default {
    components: {
        VuePerfectScrollbar,

        EditProductLeftSidebar,
        ProductInformation,
        ProductDescriptions,
        ProductAttributes,
        ProductOptions,
        RelatedProducts,
        ProductImages,
        QuantitiesInWarehouses,
        TheDigitalFile,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            product: {},
            isLoading: true,
        }
    },
    methods: {
        getResults() {
            useJwt.post('/products/get-by-id',{id: this.$route.params.id})
            .then((response) => {
                // console.log(response.data)
                this.product = response.data.data
                this.isLoading = false
                // console.log('productFilters')
                // console.log(this.$refs['sidebar'])
                if (this.product.product_type == 'file') {
                    this.$refs['sidebar']['productFilters'][7].show = true // 7 = The Digital File Tab
                }
            })
            .catch(response => {
                console.log(response);
            });
        }
    },
    // updated() {
    //     this.$refs['sidebar']['productFilters'][0].title = 111
    // },
    setup() {
        const tabOpened = ref([])

        const { route } = useRouter()
        const routeParams = computed(() => route.value.params)
        tabOpened.value = routeParams.value.filter ? routeParams.value.filter : 'product-information';

        watch(routeParams, () => {
            // eslint-disable-next-line no-use-before-define
            tabOpened.value = routeParams.value.filter
        })

        const perfectScrollbarSettings = {
        maxScrollbarLength: 150,
        }

        const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

        return {
        perfectScrollbarSettings,
        tabOpened,

        // Left Sidebar Responsive
        mqShallShowLeftSidebar,
        }
    },
};
</script>
<style scoped>
.product-edit-list-wrapper {
    background: #FFF;
}
.todo-app-list {
    padding: 1.5rem;
}
.app-fixed-search {
    display: none !important;
}
@media only screen and (max-width: 920px) {
    .app-fixed-search {
        display: block !important;
    }
}
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
