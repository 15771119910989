<template>
  <div>
    <b-form @submit.prevent="update">
      <b-row>

        <!-- description -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Description')"
            label-for="v-description"
          >
            <quill-editor
              v-model="product.description"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>

        <!-- seo_description -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Description (SEO)')"
            label-for="v-seo-description"
          >
            <quill-editor
              v-model="product.seo_description"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false,
      snowOption: {}
    }
  },
  mounted() {
    
  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/products/update-description',this.product)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$parent.product = response.data.data
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
