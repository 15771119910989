<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <template v-for="filter in productFilters">
              <b-list-group-item
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
                v-if="filter.show"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t(filter.title)}}</span>
              </b-list-group-item>
              </template>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import Locale from '@/layouts/components/Locale.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    Locale
  },
  data() {
    return {
      isDigitalProduct: true,
      product: {},
      productFilters: [
        { title: 'Product Information', icon: 'CodesandboxIcon', show: true, route: { name: 'products-edit', params: { filter: 'product-information'} } },
        { title: 'Images', icon: 'ImageIcon', show: true, route: { name: 'products-edit', params: { filter: 'product-image' } } },
        { title: 'Description', icon: 'Edit3Icon', show: true, route: { name: 'products-edit', params: { filter: 'product-descriptions' } } },
        { title: 'Product Options', icon: 'GridIcon', show: true, route: { name: 'products-edit', params: { filter: 'product-options' } } },
        { title: 'Product Attributes', icon: 'ListIcon', show: true, route: { name: 'products-edit', params: { filter: 'product-attributes' } } },
        { title: 'Related Products', icon: 'LinkIcon', show: true, route: { name: 'products-edit', params: { filter: 'related-products' } } },
        { title: 'Quantities In Warehouses', icon: 'ArchiveIcon', show: true, route: { name: 'products-edit', params: { filter: 'quantities-in-warehouses' } } },
        { title: 'The Digital File', icon: 'ArchiveIcon', show: false, route: { name: 'products-edit', params: { filter: 'digital-file' } } },
        // { title: 'Filtres', icon: 'LinkIcon', route: { name: 'related-products', params: { filter: 'related-products' } } },
        // { title: 'international-details', icon: 'LinkIcon', route: { name: 'related-products', params: { filter: 'related-products' } } },
      ]
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style lang="sass" scoped>
@import "../../../assets/scss/variables/variables.scss"

.sidebar-menu-list
  height: 100% !important

.list-group-item.active
  border-color: $primary !important

</style>
