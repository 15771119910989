<template>
  <div>
    <b-row>

      <b-col cols="12">
        <table class="table">
          <thead>
            <th>{{$t('Title')}}</th>
            <th>{{$t('Value')}}</th>
            <th></th>
          </thead>
          <tbody>
            <template v-for="(attribute, i) in product.attributes">
            <tr :key="`tr-`+i">
              <td>
                <b-form-input
                  type="text"
                  v-model="attribute.title"
                />
              </td>
              <td>
                <b-form-input
                  type="text"
                  v-model="attribute.value"
                />
              </td>
              <td>
                <b-button
                  variant="flat-danger"
                  size="sm"
                  @click="deleteAttribute(i)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </b-col>
    </b-row>

    <!-- buttons -->
    <b-row>
      <b-col cols="6" class="text-left">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="gradient-primary"
          @click="update"
        >
          <template v-if="!isLoadingSave">
            {{$t('Save')}}
          </template>
          <template v-if="isLoadingSave">
            {{$t('Saveing')}}...
            <b-spinner small />
          </template>
        </b-button>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="gradient-success"
          @click="addNewAttribute()"
        >
          <feather-icon icon="PlusSquareIcon" />
          {{$t('Add')}}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: { FeatherIcon },
  directives: {
    Ripple,
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false
    }
  },
  mounted() {

  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/products/update-attributes',{
        id: this.product.id, 
        store_id: this.$store.state.store.id, 
        attributes: this.product.attributes
      })
      .then((response) => {
        // console.log(response.data);
        this.$parent.product = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    addNewAttribute() {
      if (this.product.attributes == null) {
        this.product.attributes = []
      }
      this.product.attributes.push({title:'',value:''});
    }, 
    deleteAttribute(i) {
      this.product.attributes.splice(i, 1);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
