<template>
  <div>
    <b-row>

      <b-col cols="12">
        <h6>{{$t('The Digital File')}}</h6>
        <b-row class="match-height m-0">
          <template v-if="fileUrl">
            <b-button
              variant="primary"
              size="sm"
              @click="downloadFile()"
            >
              {{ $t('Download File') }}
              <feather-icon
                icon="DownloadIcon"
                size="15"
              />
            </b-button>
          </template>
        </b-row>
      </b-col>

      <b-col cols="12">
        <hr>

        <!-- upload image -->
        <div class="image-upload-wrap">
          <input class="file-upload-input" type='file' @change="checkFileSize($event)" accept="application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf" />
          <div class="drag-text">
            <h4 class="text-primary">

              <template v-if="!isLoadingSave">
                <feather-icon
                  icon="UploadCloudIcon"
                  size="50"
                /><br>
                {{$t('Drag and drop a file or click to add File')}}<br>
                <small class="text-warning">{{$t('Maximum size is: 10 MB')}}</small>
              </template>

              <template v-if="isLoadingSave">
                <b-spinner />
              </template>
            </h4>
          </div>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import 'vue-advanced-cropper/dist/style.css';
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false,
      isLoadingDelete: -1,
      fileUrl: null,
    }
  },
  mounted() {
    if (this.product.custom_attributes && this.product.custom_attributes.file_path) {
      this.fileUrl = this.product.custom_attributes.file_path
    }
  },
  methods: {
    checkFileSize(event) {
      let file = event.target.files[0]
      // if(!file || file.type.indexOf('image/') !== 0) return; // check file type
      if(!file) return;

      // console.log(file)
      if (file.size > (10 * 1024 * 1024)) { // 3 MB
        // error: change size
        console.log('more')
      }

      this.uploadFile(file)
    },
    uploadFile(file) {
      if (file) {
        const form = new FormData();
        form.append('file', file);
        form.append('id', this.product.id);
        
        this.isLoadingSave = true;
        console.log(form)
        useJwt.post('/products/upload-digital-file',form)
        .then((response) => {
          // console.log(response.data);
          this.$parent.$parent.product = response.data.data
          this.isLoadingSave = false
          this.$root.showToast('success', 'save', '')
        })
        .catch(error => {
          // console.log(response);
          this.isLoadingSave = false
          this.$root.showAlert('error', error.response.data.message)
        });
      }
		},
    downloadFile() {
      console.log(this.product.id)
      useJwt.get('/products/download-digital-file/'+this.product.id,
        {responseType: 'blob'} // important
      ).then((response) => {
        console.log(response.data)
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', this.product.id+'.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    deleteFile() {
      this.isLoadingDelete = true
      useJwt.post('/products/delete-digital-file',{id: this.product.id})
      .then((response) => {
        // console.log(response.data);
        this.$parent.$parent.product = response.data.data
        // this.$delete(this.$parent.$parent.product.media, i);
        this.isLoadingDelete = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingDelete = false
        this.$root.showAlert('error', error.response.data.message)
      });

    }
  }
}
</script>

<style lang="scss">
.image-upload-wrap {
  position: relative;
  margin-top: 20px;
  border: 1px dashed #ccc;
  border-radius: 10px;
}

.image-upload-wrap:hover {
  background-color: #f7f7f7;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.drag-text {
  text-align: center;
}

.drag-text h4 {
  font-weight: 100;
  padding: 30px 0;
}

</style>
