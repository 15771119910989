<template>
  <div>
    <template v-if="product.warehouses && Object.keys(product.warehouses).length > 0">
    <b-row>
      <b-col cols="12">
        <table class="table">
          <thead>
            <th>{{$t('Branch / Warehouse')}}</th>
            <th>{{$t('Quantity')}}</th>
          </thead>
          <tbody>
            <template v-for="(warehouse, i) in product.warehouses">
            <tr :key="`tr-`+i">
              <td>
                {{warehouse.name}}
              </td>
              <td>
                <b-form-input
                  type="text"
                  v-model="warehouse.pivot.quantity"
                />
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </b-col>
    </b-row>

    <!-- buttons -->
    <b-row>
      <b-col cols="6" class="text-left">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="gradient-primary"
          @click="update"
        >
          <template v-if="!isLoadingSave">
            {{$t('Save')}}
          </template>
          <template v-if="isLoadingSave">
            {{$t('Saveing')}}...
            <b-spinner small />
          </template>
        </b-button>
      </b-col>
    </b-row>
    </template>
    <template v-else>
      {{$t('There are not quntities in warehouses for this product')}}
    </template>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: { FeatherIcon },
  directives: {
    Ripple,
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false,
    }
  },
  mounted() {
    
  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/products/update-warehouses',{
        id: this.product.id, 
        store_id: this.$store.state.store.id, 
        warehouses: this.product.warehouses
      })
      .then((response) => {
        // console.log(response.data);
        this.$parent.product = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
