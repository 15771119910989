<template>
  <div>
    <b-form @submit.prevent="update">
      <b-row>

        <!-- name -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Related Products')"
            label-for="v-related-products"
          >
            <v-select
              v-model="product.related_products"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :options="products"
            >
              <template #option="{ name, largeThumbnail }">
                <b-img :src="largeThumbnail" width="50" height="50" />
                <span> {{ name }}</span>
              </template>
            </v-select>
            <div class="mt-1">
              <template v-for="(related_product, indexI) in product.related_products">
                <b-row :key="indexI" class="mb-1">
                  <b-col cols="12">
                    <b-img :src="related_product.largeThumbnail" width="50" height="50" class="mr-1" />
                    {{related_product.name}}
                  </b-col>
                </b-row>
              </template>
            </div>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: ['product'],
  data() {
    return {
      isLoadingSave: false,
      products: []
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/products/update-related-products',this.product)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$parent.product = response.data.data
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    getProducts(){
      useJwt.get('/products/get-all')
      .then((response) => {
        // console.log(response.data)
        this.products = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
