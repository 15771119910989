<template>
 <div>
    <b-modal
      id="productOptionModal"
      modal-class="modal-primary"
      :title="$t('Add') + ' ' + $t('Product Option')"
      hide-footer
      centered
    >
      <b-form @submit.prevent="saveProductOption">
        
        <!-- Name -->
        <b-form-group
          :label="$t('Name')"
          label-for="v-name"
        >
          <b-form-input
            id="v-name"
            type="text"
            v-model="productOptionForm.name"
          />
        </b-form-group>

        <!-- Product Option Type -->
        <b-form-group
          :label="$t('Product Option Type')"
          label-for="v-product_option_type_id"
        >
          <b-form-select
            id="v-product_option_type_id"
            v-model="productOptionForm.product_option_type_id"
            :options="product_option_types"
          />
        </b-form-group>        

        <!-- Product Code -->
        <b-form-group
          :label="$t('Product Code')"
          label-for="v-sku"
        >
          <b-form-input
            id="v-sku"
            type="text"
            v-model="productOptionForm.sku"
          />
        </b-form-group>

        <!-- Original Price -->
        <b-form-group
          :label="$t('Original Price')"
          label-for="v-original_price"
        >
          <b-form-input
            id="v-original_price"
            type="text"
            v-model="productOptionForm.original_price"
          />
        </b-form-group>

        <!-- Price After Discount -->
        <b-form-group
          :label="$t('Price After Discount')"
          label-for="v-price_after_discount"
        >
          <b-form-input
            id="v-price_after_discount"
            type="text"
            v-model="productOptionForm.price_after_discount"
          />
        </b-form-group>

        <!-- Quantity -->
        <b-form-group
          :label="$t('Quantity')"
          label-for="v-quantity"
        >
          <b-form-input
            id="v-quantity"
            type="text"
            v-model="productOptionForm.quantity"
          />
        </b-form-group>
        
        <!-- Availability Date -->
        <b-form-group
          :label="$t('Availability Date')"
          label-for="v-availability_date"
        >
          <flat-pickr
            v-model="productOptionForm.availability_date"
            class="form-control"
          />
        </b-form-group>
      
        <!-- Available? -->
        <b-form-group
          :label="$t('Available?')"
          label-for="v-availability"
        >
          <b-form-checkbox
            :checked="productOptionForm.availability"
            class="custom-control-primary"
            name="availability"
            v-model="productOptionForm.availability"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Add')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Adding')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: ['product_id'],
  mounted() {
    this.getResults()
  },
  data() {
    return {
      productOptionForm: {
        name: null,
        product_option_type_id: null,
        sku: null,
        original_price: null,
        price_after_discount: null,
        quantity: null,
        availability_date: '',
        availability: false,
        store_id: this.$store.state.store.id,
        product_id: this.product_id
      },
      product_option_types: {},
      isLoadingSave: false,
    }
  },
  methods: {
    openProductOptionModal() {
      this.$bvModal.show("productOptionModal")
    },
    getResults() {
      useJwt.get('/product-options/get-product-option-types')
      .then((response) => {
        // console.log(response.data)
        this.product_option_types = response.data.data
      })
      .catch(response => {
        console.log(response);
      });
    },
    saveProductOption() {
      this.isLoadingSave = true;

      useJwt.post('/product-options/store',this.productOptionForm)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide("productOptionModal")
        // this.$parent.getResults();
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../assets/scss/variables/variables.scss"

</style>
