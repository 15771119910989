var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.$t('Product Images')))]),_c('b-row',{staticClass:"match-height m-0"},[_vm._l((_vm.product.media),function(image,index){return [_c('div',{key:index,staticClass:"img-wrap mr-1 mb-1 float-left text-center"},[_c('b-img-lazy',{attrs:{"src":image.url,"width":"100","rounded":"","thumbnail":""}}),_c('br'),_c('b-button',{attrs:{"variant":"flat-danger","size":"sm","disabled":_vm.isLoadingDelete !=  -1},on:{"click":function($event){return _vm.deleteImage(index)}}},[(_vm.isLoadingDelete != index)?_c('feather-icon',{attrs:{"icon":"XIcon","size":"15"}}):_vm._e(),(_vm.isLoadingDelete == index)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]})],2)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr'),(!_vm.cropImage)?_c('div',{staticClass:"image-upload-wrap"},[_c('input',{staticClass:"file-upload-input",attrs:{"type":"file","accept":"image/jpg, image/jpeg, image/png, image/gif"},on:{"change":function($event){return _vm.checkImageDimensions($event)}}}),_c('div',{staticClass:"drag-text"},[_c('h4',{staticClass:"text-primary"},[(!_vm.isLoadingSave)?[_c('feather-icon',{attrs:{"icon":"UploadCloudIcon","size":"50"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Drag and drop a file or click to add Image'))),_c('br'),_c('small',{staticClass:"text-warning"},[_vm._v(_vm._s(_vm.$t('Best Dimensions: 1000px * 1000px')))])]:_vm._e(),(_vm.isLoadingSave)?[_c('b-spinner')]:_vm._e()],2)])]):_c('div',[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.img,"stencil-props":{
            handlers: {},
            movable: false,
            scalable: false,
          },"stencil-size":{
            width: 1000,
            height: 1000
          },"image-restriction":"stencil"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 mt-1",attrs:{"type":"submit","variant":"gradient-primary"},on:{"click":function($event){return _vm.uploadImage(null)}}},[(!_vm.isLoadingSave)?[_vm._v(" "+_vm._s(_vm.$t('Upload'))+" ")]:_vm._e(),(_vm.isLoadingSave)?[_vm._v(" "+_vm._s(_vm.$t('Uploading'))+"... "),_c('b-spinner',{attrs:{"small":""}})]:_vm._e()],2)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }