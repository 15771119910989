<template>
 <div>
    <b-modal
      id="productOptionTypeModal"
      modal-class="modal-primary"
      :title="$t('Product Option Type')"
      hide-footer
      centered
    >
      <b-form @submit.prevent="saveProductOptionType">
        
        <!-- Title -->
        <b-form-group
          :label="$t('Name')"
          label-for="v-name"
        >
          <b-form-input
            id="v-name"
            type="text"
            v-model="productOptionTypeForm.name"
          />
          <b-form-text>{{$t('Ex: Color, Size')}}</b-form-text>
        </b-form-group>

        <!-- Title -->
        <b-form-group
          :label="$t('Input Type')"
          label-for="v-input-type"
        >
          <b-form-select
            id="v-input-type"
            v-model="productOptionTypeForm.input_type"
            :options="input_types"
          />
        </b-form-group>        
      
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">
        <template v-if="productOptionTypeForm.id">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
        <template v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Add')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Adding')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  mounted() {
    
  },
  data() {
    return {
      productOptionTypeForm: {
        name: '',
        input_type: '',
        id: null
      },
      input_types: [
        { value: 'radio', text: this.$t('Radio') },
        { value: 'select', text: this.$t('Select') },
      ],
      isLoadingSave: false,
    }
  },
  methods: {
    openProductOptionTypeModal(productOptionType = null) {
      this.productOptionTypeForm = {
        name: productOptionType ? productOptionType.name : '',
        input_type: productOptionType ? productOptionType.input_type : '',
        store_id: this.$store.state.store.id,
        id: productOptionType ? productOptionType.id : null
      }
      this.$bvModal.show("productOptionTypeModal")
    },
    saveProductOptionType() {
      this.isLoadingSave = true;
      let path;
      if (this.productOptionTypeForm.id) { // update current
        path = '/product-option-types/update';
      } else { // store new
        path = '/product-option-types/store';
      }

      useJwt.post(path,this.productOptionTypeForm)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide("productOptionTypeModal")
        // this.$parent.getResults();
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../assets/scss/variables/variables.scss"

</style>
